<template>
  <ElDialog
    class="sd-device-settings-dialog d-flex justify-content-center align-items-center"
    :center="true"
    visible
    :destroy-on-close="true"
    @close="close"
  >
    <div slot="title">
      <div class="sd-device-settings-dialog-key-box d-flex py-1">
        <i class="sdicon-key mr-1 font-weight-bold text-green" />
        <span>{{ serial_number }}</span>
      </div>
      <h2 class="mt-4">
        Device Settings
      </h2>
    </div>
    <div>
      <ElForm
        ref="form"
        :model="payload"
      >
        <SdFloatLabel for-input="name">
          <ElFormItem class="mb-4">
            <ElInput
              v-model="payload.name"
              :value="name"
              type="text"
              placeholder="Device name"
              label="name"
            />
          </ElFormItem>
          <div>
            <UnitAutoComplete
              :allow-clearable="true"
              :unit-id="unitId"
              :unit-ids-to-filter="usedUnits"
              :disabled="!!unitId && status === DEVICE_STATUS.IN_USE"
              @set-unit="unitSelected"
            />
          </div>
        </SdFloatLabel>
      </ElForm>
      <footer class="sd-device-setting-dialog-button d-flex justify-content-between mt-3">
        <ElButton
          size="medium"
          @click="close"
        >
          Close
        </ElButton>
        <ElButton
          size="medium"
          type="primary"
          :disabled="uiFlags.isSaving"
          :loading="uiFlags.isSaving"
          @click="save"
        >
          Save
        </ElButton>
      </footer>
    </div>
  </ElDialog>
</template>

<script>
import { reactive } from '@vue/composition-api';
import get from 'lodash.get';
import UnitAutoComplete from '@/components/unit/UnitAutoComplete';
import { DEVICE_STATUS } from '@/constants/Device';

export default {
  name: 'DeviceSettingsDialog',
  components: { UnitAutoComplete },
  props: {
    device: {
      type: Object,
      required: false,
      default: null,
    },
    usedUnits: {
      type: Array,
      default: () => [],
    },
  },
  setup(props, context) {
    const {
 serial_number, name, unit, status,
} = props.device;
    const uiFlags = reactive({
      isSaving: false,
    });
    const payload = reactive({ serial_number, name });
    const unitId = get(unit, 'id');

    return {
      serial_number,
      name,
      payload,
      unitId,
      status,
      uiFlags,
      DEVICE_STATUS,
      close,
      save,
      unitSelected,
    };
    function close(emit) {
      context.emit('close', emit);
    }

    function unitSelected({ id }) {
      payload.unitId = id;
    }

    function save() {
      uiFlags.isSaving = true;
      context.emit('save', payload);
    }
  },
};
</script>
<style lang="scss">
.sd-device-settings-dialog {
  .el-dialog {
    width: auto;
    height: auto;

    h2 {
      margin: 0 0 0.75rem 0;
    }

    .el-dialog__header {
      text-align: start;
      padding-top: 1.5rem;

      .sd-device-settings-dialog-key-box {
        width: 5.85rem;
        border: 1px solid gray-color("dark");
        border-radius: 5px;
        color: gray-color("dark");
      }
    }

    .el-dialog__body {
      padding: 0;

      form {
        padding: 0px 25px;
      }

      footer {
        border-top: 1px solid gray-color("light");
        padding: 15px 25px;
      }
    }
  }
}
</style>
